<template>

<v-container fluid>
<InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />

<v-row>
            <v-col>
                <span :style="{color:$store.getters.getColorPalette().accentCode}">{{$store.getters.getTextMap().historian}}</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-form
    ref="usageForm"
    v-model="valid"
    
  >
        
        <v-row>
          
          <v-col>
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    :dark="$store.getters.getColorPalette().isDark"
    ></v-skeleton-loader>
                <v-menu
        v-if="!loading"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
     :dark="$store.getters.getColorPalette().isDark"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.date"
            :label="$store.getters.getTextMap().date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
           :dark="$store.getters.getColorPalette().isDark"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.date"
          @input="menu = false"
      :dark="$store.getters.getColorPalette().isDark"
        ></v-date-picker>
      </v-menu>
            </v-col>

            {{$store.getters.getTextMap().from_optional}}

              <v-col align-self="center">
              <TimePicker v-model="fromTime"/>
            </v-col>
            {{$store.getters.getTextMap().to_optional}}

            <v-col align-self="center">
              <TimePicker v-model="toTime"/>
            </v-col> 
<!-- <v-col>
    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
     :dark="$store.getters.getColorPalette().isDark"
    ></v-skeleton-loader>
    <v-menu
        v-if="!loading"
        ref="menufrom"
        v-model="menu3"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="fromTime"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
     :dark="$store.getters.getColorPalette().isDark"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="fromTime"
            label="From (optional)"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
         :dark="$store.getters.getColorPalette().isDark"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu3"
          v-model="fromTime"
          full-width
          format="24hr"
          @click:minute="$refs.menufrom.save(fromTime)"
        :dark="$store.getters.getColorPalette().isDark"
        ></v-time-picker>
      </v-menu>
            </v-col>

            <v-col>
                <v-skeleton-loader
              :dark="$store.getters.getColorPalette().isDark"
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
<v-menu
v-if="!loading"
        ref="menuto"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="toTime"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      :dark="$store.getters.getColorPalette().isDark"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="toTime"
            label="To (optional)"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          :dark="$store.getters.getColorPalette().isDark"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          v-model="toTime"
          full-width
          format="24hr"
          @click:minute="$refs.menuto.save(toTime)"
         :dark="$store.getters.getColorPalette().isDark"
        ></v-time-picker>
      </v-menu>
            </v-col> -->
             
        </v-row>
        <v-row>
            <v-col align-self="center" >
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
   :dark="$store.getters.getColorPalette().isDark"
    ></v-skeleton-loader>
                <v-btn
                v-if="!loading"
          :color="$store.getters.getColorPalette().submitbtnColor"
        :dark="$store.getters.getColorPalette().isDark"
            small
            @click="onSubmit"
          >
          {{$store.getters.getTextMap().generate_report}}

          </v-btn>
          
            </v-col>
            
        </v-row>
        </v-form>
        
        <v-row v-if="graphData.length>0">
            <v-col>
                <strong>{{$store.getters.getTextMap().trends}}
 - {{form.date}}</strong>
            </v-col>
            <v-col v-if="!loading && graphData.length>0"  align-self="center">
                <v-btn
          :color="$store.getters.getColorPalette().downloadbtnColor"
            small
            outlined
            @click="downloadPDF"
          >
          {{$store.getters.getTextMap().download_table}}

          </v-btn>
          
            </v-col>
        </v-row>

        <v-divider></v-divider>
        
        <v-row >
            <v-col>
                 <v-card><div class="center" ref="trend"></div></v-card>
            </v-col>
        </v-row>
        
        <v-divider></v-divider>
        <a ref="csvDownload" hidden></a>

</v-container>
    
</template>

<script>

//import SingleAxisLineGraphByTimestamp from '@/components/graphs/SingleAxisLineGraphByTimestamp';
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import Plotly from 'plotly.js'
import moment from 'moment'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { applyPlugin } from 'jspdf-autotable'
import TimePicker from '@/components/inputs/TimePicker'
applyPlugin(jsPDF)
export default {
    name:'ParameterTODPanel',
    props:['busbarTempModule'],
    data(){
        return {
            form:{
                date:null,
                
            },
            //date: new Date().toISOString().substr(0, 10),
            report:{
                date:null,
                
                
                },
                normalised:false,
                valid:false,
                menu:false,
                menu2:false,
                menu3:false,
            graphData:[],
            toTime:null,
            fromTime:null,
            showDismissibleAlert:false,
            loading:false,
            info:'',
            layout:{
              //title:'Compare',
              showlegend:true
            },
            streamHeaders:[
                'current_r',
                'current_y',
                'current_b',
                'current_n',
                'ambient_temp',
                'temp_r',
                'temp_y',
                'temp_b',
                'temp_n',
                
                'allowable_temp_r',
                'allowable_temp_y',
                'allowable_temp_b',
                'allowable_temp_n',
                
            ],
             objectRules:[
              v => !!v || 'Required',
            ],

        }
    },
    mounted(){

    },
    components:{
        
        InfoAlert,
        TimePicker
    },
    computed: {
      api(){
        if(this.normalised){
          return 'getNormalisedDataByParameterBetweenTimestamp'
        }else{
          return 'getDataByParameterBetweenTimestamp'
        }
      },
      fromTimeStamp(){
        if(this.form.date){
          
            let dt=this.form.date.toString()
            if(this.fromTime){
              dt+=' '+this.fromTime.toString()
              return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
          
        }else{
          return null
        }
      },
      toTimeStamp(){
        if(this.form.date){
          
            let dt=this.form.date.toString()
            if(this.toTime){
              dt+=' '+this.toTime.toString()
              return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
            }
            return Number(moment(dt,'YYYY-MM-DD').add(1,'days').format("X"));
          
        }else{
          return null
        }
      },
        
        
    },
    methods: {
      downloadPDF(){

        this.loading=true
          
            //let pdf = new jsPDF();
            
            let header=['Date',...this.streamHeaders]
            let body=[]
            for (let i of this.graphData){
                let temp=[]
                temp.push(moment.unix(Number(i.timestamp)).format('YYYY/MM/DD HH:mm:ss'))
                for(let h of this.streamHeaders){
                    if(h in i){
                        temp.push(i[h])
                    }else{
                        temp.push(null)
                    }
                }
                body.push(temp)
            }
            /*pdf.autoTable({
                head: [header],
                body: body
                })
            pdf.save(this.busbarTempModule.name+'_'+moment().format('YYYYMMDD')+'.pdf');
            */
            let csvHeader=header.join(',')+"\n"
            let csv=csvHeader
            csv+=body.map(e => e.join(",")).join("\n");
            let csvData = new Blob([csv], { type: 'text/csv' });  
            let csvUrl = URL.createObjectURL(csvData);
            //let hiddenElement = document.createElement('a');
            let hiddenElement = this.$refs.csvDownload;
            hiddenElement.href = csvUrl;
            hiddenElement.target = '_blank';
            hiddenElement.download = 'busbar_'+moment().format('YYYYMMDDHHmm') + '.csv';
            hiddenElement.click();
            this.loading=false

      },
      onSubmit(){
            this.$refs.usageForm.validate()
            if(this.valid){
              this.loading=true
              //let fromTimeStamp=this.fromTimeStamp
              //let toTimeStamp=this.toTimeStamp
              let payload={
                  module_id:this.busbarTempModule.module_id,
      from_timestamp:this.fromTimeStamp,
      to_timestamp:this.toTimeStamp
    }
    
    //console.log(payload)
    axios.post(this.$store.state.api+'getBusbarTempDataBetweenTimestamp',payload,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      
      //console.log(response.data.monthlyData)
      //context.state.monthlyData=response.data.monthlyData
//console.log(response.data.data)
//console.log('something')


if(response.data.status==='success'){
//let data=[]
//console.log(response.data)
this.graphData=response.data.data
this.paramData={}
        for(let h of this.streamHeaders){
            this.paramData[h]={
                x:[],y:[],name:h
            }
        }
        
            for(let i of this.graphData){
                for(let h of this.streamHeaders){
                    if(h in i){
                        this.paramData[h]['x'].push(moment.unix(i.timestamp).format('YYYY-MM-DD HH:mm:ss'))
                        this.paramData[h]['y'].push(Number(i[h]))
                    }

                }
        
            }
      //console.log(this.$refs.trend)
      Plotly.newPlot(this.$refs.trend,Object.values(this.paramData),this.layout,{displaylogo:false})
      //
      

      //console.log(response.data.data)
      //this.report['parameter']=this.form.parameter
      this.report['date']=this.form.date
      if(this.graphData.length==0){
          this.info = "No data to show";
            this.showDismissibleAlert = true;
      }
    }else{
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }
      this.loading=false

    })
    .catch(err=>{
    this.showDismissibleAlert=true
            this.info=err
            this.loading=false
            })

    
    
    
    
            }
        }
    
    },

}
</script>