<template>
    <v-container fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        
          <v-row no-gutters v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
          <v-col align-self="center" align="right">
            <span>
              <v-icon  color="blue" small @click="showEdit=!showEdit">
        mdi-pencil
      </v-icon>
            </span>
            <span>
               <DeleteConfirmation  v-on:confirm="deleteModule()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">
              <v-icon :color="$store.getters.getColorPalette().deletebtnColor" small @click="showDialog = true">
        mdi-delete
      </v-icon>
     </DeleteConfirmation>
            </span>
            </v-col>
        </v-row>
         <v-row v-if="showEdit">
            <v-col cols="12">
                <CreateBusbarTempModule :busbarTempModule="busbarTempModule" v-on:close="showEdit=!showEdit"/>
                </v-col>
        </v-row>
            <v-row dense>
                <v-col cols="6">
                    <v-card flat class="mx-0 my-0" :color="$store.getters.getColorPalette().background2ColorCode"  >
                      <div class="center" ref="graph1" ></div>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card flat class="mx-0 my-0" :color="$store.getters.getColorPalette().background2ColorCode"  ><div class="center" ref="graph2"></div></v-card>
                </v-col>
                
            </v-row>
        
        
            <v-row>
                <v-col>
                  <v-card flat :dark="$store.getters.getColorPalette().isDark" >
                    <v-data-table
      :headers="headers"
      :items="streamData"
      item-key="timestamp"
      :search="search"
      
      
      :loading="loading"
    loading-text="Loading... Please wait"
   :dark="$store.getters.getColorPalette().isDark"
    >
    <template v-slot:top>
      <v-toolbar
        flat
       :dark="$store.getters.getColorPalette().isDark"
      >
        <v-toolbar-title>Temp Monitoring</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
      :dark="$store.getters.getColorPalette().isDark"
        ></v-divider>
        <v-spacer></v-spacer>
        
      </v-toolbar>
      
          </template>
          
          <template v-slot:item.timestamp="{item}">
            {{convertTimeStamp(item.timestamp)}} 
            
          </template>
           <template v-slot:item.current_r="{item}">
            <td >{{parseFloat(item.current_r).toFixed(3)}}</td>
            
          </template>
           <template v-slot:item.current_y="{item}">
            <td >{{parseFloat(item.current_y).toFixed(3)}}</td>
            
          </template>
           <template v-slot:item.current_b="{item}">
            <td >{{parseFloat(item.current_b).toFixed(3)}}</td>
            
          </template>
           <template v-slot:item.current_n="{item}">
            <td >{{parseFloat(item.current_n).toFixed(3)}}</td>
            
          </template>
          <template v-slot:item.temp_r="{item}">
            <td :class="getColor(item.temp_diff_r)">{{parseFloat(item.temp_r).toFixed(3)}}</td>
            
          </template>
          <template v-slot:item.temp_y="{item}">
            <td :class="getColor(item.temp_diff_y)">{{parseFloat(item.temp_y).toFixed(3)}}</td>
            
          </template>
          <template v-slot:item.temp_b="{item}">
            <td :class="getColor(item.temp_diff_b)">{{parseFloat(item.temp_r).toFixed(3)}}</td>
            
          </template>
          <template v-slot:item.allowable_temp_r="{item}">
            <td >{{parseFloat(item.allowable_temp_r).toFixed(3)}}</td>
            
          </template>
          <template v-slot:item.allowable_temp_y="{item}">
            <td >{{parseFloat(item.allowable_temp_y).toFixed(3)}}</td>
            
          </template>
          <template v-slot:item.allowable_temp_b="{item}">
            <td >{{parseFloat(item.allowable_temp_b).toFixed(3)}}</td>
            
          </template>
          <template v-slot:item.allowable_temp_n="{item}">
            <td >{{parseFloat(item.allowable_temp_n).toFixed(3)}}</td>
            
          </template>
    </v-data-table>
  </v-card>
                </v-col>
            </v-row>
        
        <v-row>
          <v-col><BusbarTempTODPanel :busbarTempModule="busbarTempModule"/></v-col>
        </v-row>
    </v-container>

</template>

<script>
import axios from 'axios'
import Plotly from "plotly.js"
import {io} from 'socket.io-client'
import moment from 'moment'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import InfoAlert from '@/components/InfoAlert'
import CreateBusbarTempModule from '@/components/crud_components/modules/CreateBusbarTempModule'
import BusbarTempTODPanel from '@/components/panels/BusbarTempTODPanel'
export default {
    name:'BusbarModulePage',
    // props:['entureModule'],
    components:{
        InfoAlert,
        CreateBusbarTempModule,
        BusbarTempTODPanel,
        DeleteConfirmation
    },
    created(){
    //this.socket=io('https://localhost:80');
    this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
  },
  beforeDestroy() {
    //this.socket.close()
    //console.log('before destroy')
    this.stream.close()
  },
    mounted(){
      this.module_id=this.$route.params.id
        this.init()
        this.stream.on("connect_error", (err) => {
  console.log(err.message); // prints the message associated with the error
});
      this.stream.on('error',data=>{
      console.error(data)
      
    })
      this.stream.on('success',data=>{
      console.log(data)
      
    })

    this.stream.on('moduleData',data=>{
        
        this.streamData.push(data)
      if(this.streamData.length>50){
        this.streamData.shift()
        //this.parameterData[data.parameter]['y'].shift()
      }
      for(let h of this.streamHeaders1){
        if(h in data){
            this.currentData[h]['x'].push(moment.unix(data.timestamp).format('YYYY-MM-DD HH:mm:ss'))
            this.currentData[h]['y'].push(Number(data[h]))
            if(this.currentData[h]['x'].length>100){
              this.currentData[h]['x'].shift()
              this.currentData[h]['y'].shift()
            }
        }
        
      }
      for(let h of this.streamHeaders2){
        if(h in data){
            this.tempData[h]['x'].push(moment.unix(data.timestamp).format('YYYY-MM-DD HH:mm:ss'))
            this.tempData[h]['y'].push(Number(data[h]))
            if(this.tempData[h]['x'].length>100){
              this.tempData[h]['x'].shift()
              this.tempData[h]['y'].shift()
            }
        }
        
      }
      


      Plotly.newPlot(this.$refs.graph2,Object.values(this.currentData),this.layout,{displaylogo:false})
      Plotly.newPlot(this.$refs.graph1,Object.values(this.tempData),this.layout,{displaylogo:false})
      //console.log(this.streamData)
      

    })
    
     
    //this.configureParamStream()
    if(this.module_id){
            this.getModule()
            this.stream.emit('getModuleData',this.module_id)
        }
      
    },
    
    data(){
        return {
            showDialog:false,
            loading:false,
            showEdit:false,
            info:'',
            showDismissibleAlert:false,
            streamData:[],
            busbarTempModule:null,
            search:null,
            expanded:null,
            paramData:{},
            currentData:{},
            tempData:{},
            module_id:null,
            layout:{
              
              showlegend:true,
              //colorway:this.$store.getters.getColorPalette().colorTypesCodeArray,
              font:{
                          color:this.$store.getters.getColorPalette().accentCode
                        },
                        plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                        paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                    
                    autosize: true,
                    xaxis: {
                        tickangle: -12,
                        zeroline: false,
                        showgrid: true,
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode
                      },       
                      yaxis: {
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode
                      },
                      uniformtext: {
                        "mode": "hide",
                        "minsize": 1
                      }
            },
            streamHeaders:[
                'current_r',
                'current_y',
                'current_b',
                'current_n',
                'ambient_temp',
                'temp_r',
                'temp_y',
                'temp_b',
                'temp_n',
                
                'allowable_temp_r',
                'allowable_temp_y',
                'allowable_temp_b',
                'allowable_temp_n',
                
            ],
            streamHeaders1:[
                'current_r',
                'current_y',
                'current_b',
                'current_n',
                
                
            ],
            streamHeaders2:[
                
                'ambient_temp',
                'temp_r',
                'temp_y',
                'temp_b',
                'temp_n',
                
                'allowable_temp_r',
                'allowable_temp_y',
                'allowable_temp_b',
                'allowable_temp_n',
                
            ],
            headers:[
          {
            text: 'Time',
            align: 'start',
            filterable: false,
            //sortable:false,
            value: 'timestamp',
            sort: (a, b) => (Number(a)-Number(b)),
            class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
          },
          { text: 'Ambient Temp', value: 'ambient_temp', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Current R', value: 'current_r', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Current Y', value: 'current_y', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Current B', value: 'current_b',class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Temp R', value: 'temp_r',class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Temp Y', value: 'temp_y',class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Temp B', value: 'temp_b',class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName},
          { text: 'Allowable Temp R', value: 'allowable_temp_r',class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Allowable Temp Y', value: 'allowable_temp_y',class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Allowable Temp B', value: 'allowable_temp_b',class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          
          
        ],
        }
    },
methods:{
    getColor(p){
        if(!isNaN(p)){
          //console.log(p)
            if(p>0.30){
                return 'red lighten-1'
            }else if(p>0.20){
                return 'orange lighten-1'
            }else if(p>0.10){
                return 'yellow '
            }else if(p>=0){
                return 'green lighten-2'
            }else{
                return 'blue lighten-3'
            }
        }
        return 
    },
    convertTimeStamp(t){
        return moment.unix(t)
    },
    init(){
        this.paramData={}
        for(let h of this.streamHeaders){
            this.paramData[h]={
                x:[],y:[],name:h
            }
        }
        this.currentData={}
        for(let h of this.streamHeaders1){
            this.currentData[h]={
                x:[],y:[],name:h
            }
        }
        this.tempData={}
        for(let h of this.streamHeaders2){
            this.tempData[h]={
                x:[],y:[],name:h
            }
        }
    },
    
    getModule(){
        if(this.module_id){
            this.loading=true
            axios.post(this.$store.state.api+'getBusbarTempModuleByID',{module_id:this.module_id},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            
                            this.edit=false
                            this.busbarTempModule=response.data.module
                            //this.info=response.data
                            //this.showDismissibleAlert=true
                            //this.$store.dispatch('refreshModules')
                            
                        }else{
                            //this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                        this.loading=false
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })

        }
    },
    deleteModule(){
         if(this.module_id){
            this.loading=true
            let payload={
                module_id:this.module_id
            }
            axios.post(this.$store.state.api+'deleteEntureModule',payload,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.loading=false
                            this.edit=false
                            this.$store.dispatch('refreshModules')
                            
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
        }
            
        },
          cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
  },
  watch:{
        // entureModule:function (){
        //     this.getModule()
        // },
        '$route'() {
                  this.module_id = this.$route.params.id
                  this.getModule()

    },
    }

}
</script>